html {
    overflow-y: scroll;
}

body {
    width: 80%;
    height: 100%;
    margin: auto;
    background-image: url(img/bd-quarter.png);
    background-repeat: no-repeat;
    background-size: 100%;
    color: #414141;
}

nav {
    float: right;
    font-family: Karla, Helvetica, sans-serif;
    font-size: 24px;
    font-weight: 700;
}

#about-container {
    float: right;
    width: 60%;
}

#logo img {
    float: left;
    width: 40px;
    height: 40px;
    opacity: 0.5;
    transition: ease 0.25s;
}

#logo img:hover {
    opacity: 1;
}

header {
    margin-top:80px;
    margin-bottom: 80px;
}

header a {
    color: #414141;
    text-decoration: none;
    padding-left: 40px;
}

.unselected {
    opacity: 0.5;
    transition: ease 0.25s;
}

.unselected:hover {
    opacity: 1;
}

h1 {
    font-family: Overpass, Helvetica, sans-serif;
    font-size: 72px;
    font-weight: 800;
    line-height: 72px;
}

h2 {
    font-family: Karla, Helvetica, sans-serif;
    font-size: 24px;
    font-weight: 400;
}

h3 {
    font-family: Overpass, Helvetica, sans-serif;
    font-size: 24px;
    font-weight: 800;
    line-height: 24px;
}

p {
    font-family: Karla, Helvetica, sans-serif;
    font-size: 20px;
    font-weight: 400;
}

.action-link {
    text-decoration: underline;
    float: right;
    font-weight: 700;
}

.pastworks {
    color: #414141;
}

.clickable {
    cursor: pointer;
    transition: ease 0.25s;
    
}
.clickable:hover {
    color: #f37120;
}

.orange {
    color: #f37120;
    font-weight: 700;
}

.titles {
    float: right;
    text-align: right;
    color: #414141;
    text-decoration: none;
    margin-bottom: auto;
}

.blurbs {
    /* float: right; */
    display: block;
    text-align: right;
    color: #414141;
}

.resume {
    text-align: right;
    color: #414141;
}

.berkeley {
    color: navy;
    transition: ease 0.25s;
    display: inline-block;
    font-weight: 700;
}

.berkeley:hover {
    color: white;
    background-color: navy;
}

.optimizely {
    color: #0037fe;
    transition: ease 0.25s;
    display: inline-block;
    font-weight: 700;
}

.optimizely:hover {
    color: white;
    background-color: #0037fe;
}

.twitchtweets {
    color: #6441a5;
    transition: ease 0.25s;
    display: inline-block;
    font-weight: 700;
}

.twitchtweets:hover {
    color: white;
    background-color: #6441a5;
}

.here, .safely {
    color: rgba(0, 175, 170, 1);
    transition: ease 0.25s;
    display: inline-block;
    font-weight: 700;
}

.here:hover, .safely:hover {
    color: white;
    background-color: rgba(0, 175, 170, 1);
}

.mdb {
    color: #58c0ff;
    transition: ease 0.25s;
    display: inline-block;
    font-weight: 700;
}

.mdb:hover {
    color: white;
    background-color: #58c0ff;
}

.innod {
    color: #1b93c0;
    transition: ease 0.25s;
    display: inline-block;
    font-weight: 700;
}

.brandon {
    display: inline-block;
    width: 280px;
}

.brandon img {
    width: 100%;
}

.selected {
    color: #f37120;
    text-decoration: underline;
}

.social {
    display: block;
    text-align: left;
    width: 100%;
}

.social img {
    margin-left: 1vw;
    margin-right: 1vw;
    /* margin: auto; */
    text-align: left;
    width: 52px;
    height: 52px;
    opacity: 0.5;
    transition: ease 0.25s;
}

.social img:hover {
    opacity: 1;
}

.proj-panel {
    position: relative;
    display: inline-block;
    margin-bottom: 32px;
    width: 45%;
}

.proj-panel-image {
  display: block;
  width: 100%;
  height: auto;
}

.shadow {
    box-shadow: 10px 10px 20px rgba(64, 64, 64, 0.15);
    transition: ease 0.25s;
}

.shadow:hover {
    box-shadow: 10px 10px 20px rgba(64, 64, 64, 0.25);   
}

.left {
    float: left;
}

.right {
    float: right;
}

.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: ease 0.25s;
}

.proj-text {
    font-family: Overpass, Helvetica, sans-serif;
    font-size: 3vw;
    font-weight: 800;
    color: white;
    height: 100%;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.date-overlay {
    font-family: Karla, Helvetica, sans-serif;
    font-size: 1.7vw;
    font-weight: 400;
    margin-top: 10px;
    margin-bottom: 10px;
}

.text-overlay {
    font-family: Karla, Helvetica, sans-serif;
    font-size: 1.7vw;
    font-weight: 400;
}

.subtext-overlay {
    font-family: Karla, Helvetica, sans-serif;
    font-style: italic;
    font-size: 1.5vw;
    font-weight: 400;
    margin-top: 20px;
}

 #whitney, #hira {
    background-color: rgba(240, 199, 63, 0.9);
}

#bdla {
    background-color: rgba(0, 0, 0, 0.75);
}

#innod, #restoration {
    background-color: rgba(27,147,192,0.85);
}

#bottle {
    background-color: rgba(44, 191, 199, 0.75);
}

#habbit {
    background-color: rgba(243, 113, 32, 0.75);
}

#here, #safely {
    background-color: rgba(0, 175, 170, 0.8);
}

#twitchtweets {
    background-color: rgba(100, 65, 165, 0.85);
}

#engage {
    background-color: rgba(58, 132, 195, 0.8);
}

#ford {
    background-color: rgba(29, 163, 218, 0.85);
}

#optimizely {
    background-color: rgba(0, 65, 245, 0.8);
}

#akp {
    background-color: rgba(25, 32, 78, 0.85);
}

.proj-panel:hover #twitchtweets, .proj-panel:hover #bottle, .proj-panel:hover #habbit, .proj-panel:hover #hira, .proj-panel:hover #bdla, .proj-panel:hover #here, .proj-panel:hover #safely, .proj-panel:hover #innod, .proj-panel:hover #restoration, .proj-panel:hover #whitney, .proj-panel:hover #engage, .proj-panel:hover #optimizely, .proj-panel:hover #ford, .proj-panel:hover #akp {
    opacity: 1;
}

@media (orientation: portrait) {

    #container {
        width: 100%;
        margin-top: 40px;
    }

    #about-container, #home-container {
        float: none;
        width: 100%;
    }
    
    body {
        width: 90%;
        background-image: url(img/bd-square.png);
    }
    
    #logo img {
        float: left;
        width: 40px;
        height: 40px;
    }
    
    nav {
        font-size: 16px;
    }

    header a {
        padding-left: 20px;
    }
    
    .titles {
        text-align: center;
        float: none;
        margin-bottom: 20px;
    }
    
    .blurbs {
        text-align: center;
        margin-left: 0;
        float: none;
        display: inline-block;
        width: 100%;
    }
    
    h1 {
        font-size: 48px;
        line-height: 48px;
    }
    
    h2 {
        padding-top: 20px;
        font-size: 20px;
    }
    
    .action-link {
        text-align: center;
        display: block;
        width: 100%;
    }

    .brandon {
        width: 100%;
        text-align: center;
        display: block;
        margin-bottom: 20px;
    }
    
    .brandon img {
        width: 200px;
    }

    .social {
        width: 100%;
        float: none;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .social img {
        float: none;
        width: 48px;
        height: 48px;
        margin-left: 20px;
        margin-right: 20px;
        opacity: 1;
    }

    header {
        margin-top:40px;
        margin-bottom: 80px;
    }

    .proj-panel {
        margin: auto;
        float: none;
        display: block;
        justify-content: center;
        text-align: center;
        margin-bottom: 20px;
        width: 100%;
    }

    .proj-text {
        font-size: 6.8vw;
    }
    
    .date-overlay {
        font-size: 4.2vw;
    }
    
    .text-overlay {
        font-size: 4.2vw;
    }
    
    .subtext-overlay {
        font-size: 3.8vw;
        margin-top: 10px;
    }

    #twitchtweets, #whitney, #hira, #bdla, #innod, #restoration, #bottle, #habbit, #here, #safely, #engage, #ford, #optimizely, #akp {
        opacity: 1;
    }

    .shadow {
        box-shadow: 5px 5px 10px rgba(64, 64, 64, 0.15);
        transition: ease 0.25s;
    }

    .shadow:hover {
        box-shadow: 5px 5px 10px rgba(64, 64, 64, 0.25);   
    }
}
