html {
    overflow-y: scroll;
}

body {
    width: 80%;
    height: 100%;
    margin: auto;
    background-image: url("img/bd-quarter.png");
    background-repeat: no-repeat;
    background-size: 100%;
    color: #414141;
}

subtitle {
    font-family: Overpass, Helvetica, sans-serif;
    font-size: 48px;
    font-weight: 800;
    margin: auto;
    margin-bottom: 1%;
    margin-top: 0;
    line-height: 48px;
    width: 100%;
    display: inline-block;
}

.background {
    display: inline-block;
    text-align: right;
}

.right-wrapper {
    display: block;
    text-align: right;
}

.bg-writeup {
    display: inline-block;
    width: 80%;
    text-align: right;
    margin-right: 0;
    margin-bottom: 40px;
}

.left-wrapper {
    display: block;
    text-align: left;
}

.writeup {
    display: inline-block;
    width: 80%;
    text-align: left;
    margin-left: 0;
    margin-bottom: 40px;
}

nav {
    float: right;
    font-family: Karla, Helvetica, sans-serif;
    font-size: 24px;
    font-weight: 700;
}

#home-container {
    float: right;
    width: 100%;
}

#logo img {
    float: left;
    width: 40px;
    height: 40px;
    opacity: 0.5;
    transition: ease 0.25s;
}

#logo img:hover {
    opacity: 1;
}

header {
    margin-top: 80px;
}

header a {
    color: #414141;
    text-decoration: none;
    padding-left: 40px;
}

.unselected {
    opacity: 0.5;
    transition: ease 0.25s;
}

.unselected:hover {
    opacity: 1;
}

h1 {
    font-family: 'Overpass', serif;
    font-size: 72px;
    font-weight: 800;
    line-height: 72px;
}

h2 {
    font-family: Karla, Helvetica, sans-serif;
    font-size: 24px;
    font-weight: 400;
}

.action-link {
    text-decoration: underline;
    /* float: right; */
    font-weight: 700;
}

.pastworks {
    color: #414141;
}

.clickable {
    cursor: pointer;
    transition: ease 0.25s; 
}

.clickable:hover {
    color: #f37120;
}

orange {
    color: #f37120;
    font-weight: 700;
}

.titles {
    /* float: right; */
    text-align: right;
    color: #414141;
    text-decoration: none;
    margin-bottom: auto;
}

.blurbs {
    /* float: right; */
    text-align: right;
    color: #414141;
}

.button {
    width: 100%;
    height: auto;
}

.filter {
    text-align: left;
    display: block;
    width: 60%;
    padding-top: 16px;
}

.buttonBox {
    cursor: pointer;
    display: inline-block;
    position: relative;
    width: 24%;
}

.hoverButton {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  transition: 0.25s ease;
}

.buttonBox:hover .hoverButton {
  opacity: 1;
}

.hoverButtonOn {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 1;
}

.react {
    text-align: right;
}

.projects {
    text-align: left;
    display: inline-block;
    margin-top: 20px;
    margin-bottom: 20px
}

.orange {
    color: #f37120;
    transition: ease 0.25s;
    display: inline-block;
    font-weight: 700;
}

.orange:hover {
    color: white;
    background-color: #f37120;
}

.bottle {
    color: rgba(44, 191, 199, 1);
    transition: ease 0.25s;
    display: inline-block;
    font-weight: 700;
}

.bottle:hover {
    color: white;
    background-color: rgba(44, 191, 199, 1);
}

.hira {
    color: rgba(240, 199, 63, 1);
    transition: ease 0.25s;
    display: inline-block;
    font-weight: 700;
}

.hira:hover {
    color: white;
    background-color: rgba(240, 199, 63, 1);
}

.ford {
    color: rgba(29, 163, 218, 1);
    transition: ease 0.25s;
    display: inline-block;
    font-weight: 700;
}

.ford:hover {
    color: white;
    background-color: rgba(29, 163, 218, 1);
}

yellow {
    color: rgba(240, 199, 63, 1);
    font-weight: 700;
}

here {
    color: rgba(0, 175, 170, 1);
    font-weight: 700;
}

bottle {
    color: rgba(44, 191, 199, 1);
    font-weight: 700;
}

engage {
    color: rgba(58, 132, 195, 1);
    font-weight: 700;
}

.engage {
    color: rgba(58, 132, 195, 1);
    transition: ease 0.25s;
    display: inline-block;
    font-weight: 700;
}

.engage:hover {
    color: white;
    background-color: rgba(58, 132, 195, 1);
}

.proj-collection {
    width: 100%;
    display: inline-block;
    text-align: center;
}

.proj-shadow {
    box-shadow: 10px 10px 20px rgba(64, 64, 64, 0.15);
}

.proj-img20 {
    vertical-align: middle;
    width: 300px;
    margin: 0 20px 32px 20px;
}

.proj-img30 {
    vertical-align: middle;
    width: 440px;
    margin: 0 20px 32px 20px;
}

.proj-img40 {
    vertical-align: middle;
    width: 480px;
    margin: 0 20px 32px 20px;
}

.proj-img100 {
    vertical-align: middle;
    width: 1800px;
}

.proj-photo {
    width: 800px;
    display: block;
    justify-content: center;
    text-align: center;
    float: none;
    margin: auto;
    margin-bottom: 40px;
}

.column-wrapper {
    width: 100%;
    display: block;
}

.third-column {
    width: 30%;
    margin: 0 .5% 0 .5%;
    display: inline-block;
    vertical-align: top;
}

.half-column {
    width: 40%;
    margin: 0 .5% 0 .5%;
    display: inline-block;
    vertical-align: top;
}

.proj-gif {
    margin: -5% 0 5% 0;
}

.scope {
    width: 100%;
    float: left;
    text-align: left;
    color: #414141;
    padding-left: 1%;
    border-left: 4px solid #414141;
}

@media (orientation: portrait) {

    #container {
        width: 100%;
    }

    #home-container {
        float: none;
        width: 100%;
    }
    
    body {
        width: 90%;
        margin: auto;
        background-image: url(img/bd-square.png);
    }

    #logo img {
        float: left;
        width: 40px;
        height: 40px;
    }
    
    nav {
        font-size: 16px;
    }

    header {
        margin-top: 20px;
    }

    header a {
        padding-left: 20px;
    }
    
    .titles {
        text-align: center;
        float: none;
        margin-top: 60px;
        margin-bottom: 20px;
    }
    
    .blurbs {
        text-align: center;
        margin-left: 0;
        display: inline-block;
    }

    subtitle {
        font-size: 40px;
        line-height: 40px;
        margin-bottom: 16px;
    }
    
    h1 {
        font-size: 48px;
        line-height: 48px;
    }
    
    h2 {
        padding-top: 20px;
        font-size: 24px;
    }
    
    .action-link {
        text-align: center;
        display: block;
    }

    .react {
        text-align: center;
    }

    .buttonBox {
        cursor: pointer;
        display: inline-block;
        position: relative;
        width: 33%;
    }

    .filter {
        float: none;
        width: 100%;
        text-align: center;
        padding-top: 0;
    }

    .projects {
        float: none;
        margin-top: 0;
        margin-bottom: 0
    }

    .buttonBox:hover .hoverButton {
        opacity: 0;
      }

    .proj-img20, .proj-img30, .proj-img40 {
        width: 100%;
        margin: 0 0 10% 0;
    }

    .proj-photo {
        width: 80%;
        margin: auto;
        margin-top: -5%;
        margin-bottom: 5%;
    }

    .column {
        width: 100%;
        text-align: center;
    }

    .proj-gif {
        justify-content: center;
        text-align: center;
    }

    .third-column {
        width: 100%;
        text-align: center;
    }

    .half-column {
        width: 100%;
        text-align: center;
    }
}
